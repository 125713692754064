import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/NewService.css";
import { APIEndtPoint } from "./Apis";
import { Link, useNavigate } from "react-router-dom";

function NewService() {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [vendorAddress, setVendorAddressData] = useState([]);
  const [error, setError] = useState(null);
  const [serviceDetails, setServiceDetails] = useState([
    {
      title: "",
      description: "",
      short_title: "",
      image: null,
      content: "",
      price: "",
    },
  ]);

  const handleAddService = () => {
    setServiceDetails([
      ...serviceDetails,
      {
        title: "",
        description: "",
        short_title: "",
        image: null,
        content: "",
        price: "",
      },
    ]);
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServiceDetails = [...serviceDetails];
    updatedServiceDetails[index][field] = value;
    setServiceDetails(updatedServiceDetails);
  };

  const handleImageChange = (index, file) => {
    const updatedServiceDetails = [...serviceDetails];
    updatedServiceDetails[index].image = file;
    setServiceDetails(updatedServiceDetails);
  };

  const handleCategoryChange = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${APIEndtPoint}/show_categories_product`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const response = await fetch(APIEndtPoint + "v1/addresses", {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setVendorAddressData(data[0]);
        } else {
          const errorData = await response.json();
          throw new Error(
            errorData.errors || `HTTP error: Status ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    if (token) {
      fetchAddressData();
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category_id", selectedCategory);
    formData.append("vendor_address_id", vendorAddress.id);
    serviceDetails.forEach((service, index) => {
      formData.append(`product_details[][title]`, service.title);
      formData.append(`product_details[][description]`, service.description);
      formData.append(`product_details[][short_title]`, service.short_title);
      formData.append(`product_details[][content]`, service.content);
      formData.append(`product_details[][price]`, service.price);

      if (service.image) {
        formData.append(`product_details[][image]`, service.image);
      }
    });

    try {
      const response = await fetch(
        APIEndtPoint + "v1/vendors/add_vendor_product",
        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Added vendor product:", data);
        setError(null);
        navigate("/dashboard/vendor_services");
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.errors || `HTTP error: Status ${response.status}`
        );
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="main-container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="fw-bold text-primary text-dark">Add New Service</h2>
        <Link
          to="/dashboard/services"
          className="btn btn-primary add-service-btn"
        >
          Back to Service
        </Link>
      </div>

      <form className="bg-light p-3 rounded" onSubmit={handleSubmit}>
        <div className="mb-4">
          <h5 className="fw-bold">Service Details</h5>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label className="form-label fw-bold h6">Select Category</label>
              <select
                className="form-select uniform-width"
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {serviceDetails.map((service, index) => (
            <div key={index} className="service-details-section mb-4">
              <div className="row rounded shadow-sm">
                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">
                    Service Sort Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter service name"
                    value={service.short_title}
                    onChange={(e) =>
                      handleServiceChange(index, "short_title", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">
                    Service Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter service full name"
                    value={service.title}
                    onChange={(e) =>
                      handleServiceChange(index, "title", e.target.value)
                    }
                  />
                </div>

                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">Service Price</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter service price"
                    value={service.price}
                    onChange={(e) =>
                      handleServiceChange(index, "price", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">Service Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) =>
                      handleImageChange(index, e.target.files[0])
                    }
                  />
                </div>

                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">Description</label>
                  <ReactQuill
                    value={service.description}
                    onChange={(value) =>
                      handleServiceChange(index, "description", value)
                    }
                    className="custom-quill-editor rounded-content"
                    placeholder="Enter service description"
                  />
                </div>

                <div className="col-md-6 mb-1 py-1">
                  <label className="form-label fw-bold h6">Content</label>
                  <ReactQuill
                    value={service.content}
                    onChange={(value) =>
                      handleServiceChange(index, "content", value)
                    }
                    className="custom-quill-editor"
                    placeholder="Enter service content"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-between mb-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddService}
          >
            Add Another Service <i className="bi bi-plus-circle ms-2"></i>
          </button>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewService;
